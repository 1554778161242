// variables
@import  './variables';

// partials
@import './Components/hero';
@import './Components/home';
@import './Components/navbar';
@import './Components/about';
@import './Components/title';
@import './Components/subtitle';
@import './Components/logo';
@import './Components/goals';
@import './Components/projects';
@import './Components/card';
@import './Components/contact';
@import './Components/footer';

body {
  background: $background;
  scroll-snap-type: y mandatory;
}

.separator{
  border-top:5px solid $yellow;
  color: $yellow;
  margin: 0 auto;
  width: 5%;

  &--card{
    width:18%;
  }

}

.nezmah__logo {
  height: 20px;
}