.Title {
  color:$grey--dark;
  font-family: "Playfair Display", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  font-size: px;
  text-transform: uppercase;
  text-align:center;

  &--yellow{
    color: $yellow;
  }
}
