#About {
// @media (min-width:577px) and(max-width:768px){
//    margin-top: 7rem !important;
// }

//   @media (min-width: 769px) and (max-width: 922px) {
//     margin-top: 8rem !important;
//     min-height: 100vh;
//   }

//   @media (min-width: 923px) and (max-width: 1999px) {
//     margin-top: 13rem !important;
//   }

//   @media (min-width: 2000px) {
//     margin-top: 38rem !important;
//   }

  .about__title {
    padding: 4rem 0;
  }

  .about__container {
    border: solid 2px $yellow;
    margin-bottom: 5rem;

    .subtitle {
      @media (min-width: 993px) {
        text-align: left;
      }
      text-align: center;
    }

    .about__content {
      font-family: $subtitle;
      font-size: 20px;
      font-weight: 200;
      letter-spacing: -0.1px;
      text-align: center;

      @media (min-width: 577px) {
        text-align: justify;
      }
    }

    .about__img {
      border-radius: 50%;
      height: 180px;
      width: 180px;
      @media (min-width: 992px) {
        margin-left: 4rem;
      }
    }
    .row {
      @media (min-width: 992px) {
        justify-content: center;
      }
      justify-content: space-evenly;
    }
    .Logo__title {
      margin-top: -11%;
      color: darken($blue, 40%);
      font-weight: 500;
      word-break: break-all;
    }
  }
}
