.footer {
  background: $footer;
  height: 100%;

  .footer__copy {
    display: inline;
    margin: 0 auto;
    position: relative;

    img {
      height: 16px;
      margin-right: 8px;
    }

    p {
      color: $grey;
      display: inline-block;
      font-family: $open;
      font-size: 14px;
      text-transform: uppercase;

      span {
        color: $yellow;
      }
    }
  }

  button {
    background-color: #161b2a;
    border: 0;
    border-radius: 2px;
    color: $grey;
    float: right;
    font-size:18px;
    height: 40px;
    line-height: 40px;
    margin-right: 1rem;
    margin-top: -0.9em;
    width: 40px;
  }
  .footer_awesome{
  font-weight: 400;
  }
}
