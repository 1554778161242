.homenav {
  background: $overlay;
  // height: 3.3rem;
  max-width: 100%;

  .homenav__link{
    color: $grey !important;
  }
  .navbar-toggler-icon{
    color: $grey !important;
  }
}
  .hidden {
    display: none;
  }
