#Hero {
  height: 95vh;
  // padding-bottom: 19rem;
  // @media (min-width: 99px) {
  //   margin-bottom: 7rem;
  // }
@media(max-width: 767px){
   background: url("../../Assets/Images/nezmah-background.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5.6rem;
    padding-bottom: 2rem;
    object-fit: fill;
}
  @media (max-width: 320px) { height: 56vh;}
  @media (min-width:321px) and (max-width: 375px) { height: 60vh;}
  @media (min-width:376px) and (max-width: 425px) { height: 64vh;}
  @media (min-width:426px) and (max-width: 768px) { height: 72vh;}
  @media (min-width:769px) and (max-width: 1024px) { height: 93vh;}
  @media (min-width:2000px) { height: 104vh;}

  .video {
    // @media (min-width: 576px) and (max-width: 767px) {
    //   height: 67vh;
    //   object-fit: fill;
    // }

    @media (min-width: 768px) and( max-width:991px) {
      height: 100vh;
      object-fit: fill;
    }
    // @media (min-width: 922px) {
    //   height: 120vh;
    //   object-fit: fill;
    // }
    width: 100%;
    height: 120vh;
    object-fit: none;
    margin-top: -5.6rem;
    top: 0;
    z-index: 0;
    object-fit: fill;
  }

  .hero__title {
    margin-top: 5rem;
    @media (min-widht: 577px) {
      margin-top: 8rem;
    }

    .display {
      color: $yellow;
      font-family: $display;
      font-size: 4rem;
      font-weight: bold;
      letter-spacing: 10px;
      mix-blend-mode: lighten;
      opacity: 10;

      @media (max-width: 320px) {
        font-size: 3rem;
      }

      @media (min-width: 600px) and (max-width: 768px) {
        font-size: 6rem;
      }

      @media (min-width: 769px) {
        font-size: 8rem;
      }
    }

    .subtitle {
      color: $blue;
      font-family: $subtitle;
      font-weight: 600;
      mix-blend-mode: lighten;
    }
  }
}
